
import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class workbench extends Rest {
  /**
   * get 我的已办
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getDone (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/done`,
      params
    })
  }

  /**
   * get 我的待办
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getTodo (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/todo`,
      params
    })
  }

  /**
   * 我的待办 一览
   * @param {*} params
   * @returns
   */
  getPage (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/page`,
      params
    })
  }

  /**
   * 获取融资业务信息
   * @param {*} params
   * @returns
   */
  getFinBusinessInfo (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getFinBusinessInfo`,
      params
    })
  }
}
