<template>
  <!-- 我的待办 -->
  <div class="concent">
    <!-- 查询表单 -->
    <base-form
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        />
      </div>
    </base-form>
    <!-- 数据表格 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :key="type"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      :webPage="false"
      class="main-page-table"
      tableKey="resultList"
      totalKey="totalCount"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <IconButton
          content="查看"
          icon="iconfont iconchakan"
          @click.prevent="jump(scope.row)"
        />
      </template>
    </base-table>
    <PreView :fileId="fileId" :fileType="fileType" :isOpen="true" :count="fileCount"/>
  </div>
</template>
<script>
// import { allSupportFileTypes } from '@/components/pre-view/pre-view.js'
// import { progressApi } from '@/api/businessApi'
import PreView from '@/components/pre-view/pre-view.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import { myDoneApi, finBusinessInfoApi } from '@/api/workbenchApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import Storage from '@/utils/storage'
export default {
  name: 'done',
  components: { BaseForm, BaseTable, IconButton, BaseButton, PreView },
  data () {
    return {
      fileCount: 0,
      fileId: '',
      fileType: '',
      loadCount: 0,
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        sort: 'END_TIME_DESC',
        processDefinitionKeys: ['BPM_QZJF_FINANCE_PROCESS', 'BPM_QZJF_REPLENISH_CONTRACT']
      },
      type: 'YB',
      fold: false
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return myDoneApi
    }
  },
  methods: {
    /**
     * 路由跳转
     */
    jump (row) {
      finBusinessInfoApi.getFinBusinessInfo({
        businessId: row.bizId,
        instanceId: row.instanceId || null,
        taskStatus: '2',
        actionUrl: row.actionUrl,
        taskName: row.taskName
      }).then(resData => {
        if (!resData.data) {
          this.warning('没获取到融资业务信息')
          return
        }
        row.finBusuinessInfoVO = resData.data
        row.businessNo = resData.data.businessNo
        row.gysId = resData.data.gysId
        row.keyId = resData.data.keyId
        row.batchNum = resData.data.batchNum
        row.finaaceProductName = resData.data.finaaceProductName

        if (row.actionUrl) {
          if (row.finBusuinessInfoVO && row.finBusuinessInfoVO.statusName === '评审会落实') {
            row.type = '1'
          } else if (row.finBusuinessInfoVO && row.finBusuinessInfoVO.statusName === '评审会批复') {
            row.type = '2'
          }

          if (row.actionUrl.indexOf(';') > 0) {
            row.newActionUrl = row.actionUrl.split(';')[0]
          }

          if (row.finBusuinessInfoVO) {
            row.capitalSideProductId = row.finBusuinessInfoVO.capitalSideProductId
          }
          row.businessId = row.bizId
          row.keyId = row.bizId
          Storage.setLocal('contractInfo', row)
          Storage.setSession('row', row)
          Storage.setSession('examine', row)
          Storage.setLocal('admittanceInfo', row)
          // if (row.newActionUrl === '/business/decisionMake') { // 融资决议查看为预览 单独处理
          //   const params = {
          //     pageSize: 10,
          //     pageIndex: 1,
          //     processStatus: 'FINANCING_RESULT',
          //     businessNo: row.businessNo
          //   }
          //   progressApi.getPage(params).then(res => {
          //     if (res.data) {
          //       this.fileId = res.data.records[0].fileId
          //       this.fileType = res.data.records[0].fileName ? res.data.records[0].fileName.split('.').pop() : ''

          //       if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
          //         this.warning(`不能预览的文件类型：[${this.fileType}]`)
          //         return
          //       }

          //       this.fileCount++
          //     }
          //   })
          // }
          if (row.newActionUrl === '/business/essentialFactor/ImplementAudit') {
            this.$router.push({
              path: row.newActionUrl,
              query: {
                keyId: row.bizId,
                instanceId: row.instanceId,
                businessId: row.bizId,
                type: 'V',
                businessNo: row.businessNo,
                showStep: 'show'
              }
            })
          } else {
            this.$router.push({
              path: row.newActionUrl,
              query: {
                keyId: row.bizId,
                instanceId: row.instanceId,
                businessId: row.bizId,
                type: 'details',
                businessNo: row.businessNo,
                gysId: row.gysId
              }
            })
          }
        }
      })
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        sort: 'END_TIME_DESC'
      }
    },
    formatTime (val) {
      if (val) {
        const time = new Date(val)
        return time.getFullYear() +
      '-' +
      (time.getMonth() + 1) +
      '-' +
      time.getDate()
      } else {
        return '--'
      }
    },
    // 查询
    handleFilter () {
      if (this.queryParas.time) {
        const timeArr = this.queryParas.time
        this.$set(this.queryParas, 'startTime', this.formatTime(timeArr[0]))
        this.$set(this.queryParas, 'endTime', this.formatTime(timeArr[1]))
      }
      this.loadCount++
    }
  },
  activated () {
    this.handleFilter()
  },
  mounted () {
    this.handleFilter()
  }
}
</script>
<style lang="scss" scoped>
.concent {
  height: calc(100% - 60px);
}
</style>
