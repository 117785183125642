import { formatDate } from '@/utils/auth/common'
const setpreRepayDateTime = () => {
  return {
    disabledDate (time) {
      return time.getTime() < new Date('1900-01-01')
    }
  }
}
export const fromConfig = (content) => {
  return [
    {
      label: '关键字',
      prop: 'keyWord',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      }
    },
    {
      label: '流程名称',
      prop: 'processName',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      }
    },
    {
      label: '任务名称',
      prop: 'taskName',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      }
    },
    {
      label: content.type === 'YB' ? '办理时间' : '发起时间',
      prop: 'time',
      tag: 'el-date-picker',
      isHidden: content.fold,
      span: 6,
      attrs: {
        'picker-options': setpreRepayDateTime(),
        editable: false,
        isRange: true,
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        placeholder: '选择时间范围'
      }
    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '流程名称',
      prop: 'processName',
      formatter: row => {
        return row.processName || '--'
      },
      'show-overflow-tooltip': true,
      width: '200px'
    },
    {
      label: '任务名称',
      prop: 'taskName',
      formatter: row => {
        return row.taskName || '--'
      },
      'show-overflow-tooltip': true,
      width: '200px'
    },
    {
      label: '关键字',
      prop: 'keywords',
      formatter: row => {
        return row.keywords || '--'
      },
      'show-overflow-tooltip': true
    },
    // {
    //   label: '发起人',
    //   prop: 'organizerName',
    //   formatter: row => {
    //     return row.organizerName || '--'
    //   },
    //   width: '160px'
    // },
    {
      label: '发起时间',
      prop: 'processCreateTime',
      showOverflowTooltip: true,
      formatter: row => {
        return formatDate(row.processCreateTime, 'YY-MM-DD hh:mm:ss')
      },
      isHidden: content.type === 'YB',
      width: '160px'
    },
    {
      label: '到达时间',
      prop: 'createTime',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.createTime, 'YY-MM-DD hh:mm:ss')
      },
      width: '160px'
    },
    {
      label: '办理时间',
      prop: 'endTime',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.endTime, 'YY-MM-DD hh:mm:ss')
      },
      isHidden: content.type === 'DB',
      width: '160px'
    },
    {
      label: '操作',
      prop: 'action',
      width: '80px',
      fixed: 'right'
    }
  ]
}
